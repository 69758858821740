import {
  ImmediateConsultExperiment,
  useImmediateConsultVariant
} from '@/components/experiments/EligibilityVariantImmediateConsult';
import settings from '@/constants/constants';
import { GoogleAnalyticsEventName } from '@/constants/types';
import useGoogleTagManager from '@/hooks/useGoogleTagManager';
import { formatStringToSha256Hash } from '@/utils/ga-hashing';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PhoneIcon from '@mui/icons-material/Phone';
import { Alert, Box, Button, Stack, TextField, Typography } from '@mui/material';
import { blueGrey, lightBlue } from '@mui/material/colors';
import { forwardRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import 'react-phone-number-input/style.css';

const CustomPhoneInput = forwardRef(({ ...props }, ref) => (
  <TextField
    {...props}
    inputRef={ref} // Forward the ref
    variant="standard"
    label="Phone number (In case your call disconnects)"
    fullWidth
  />
));
CustomPhoneInput.displayName = 'CustomPhoneInput';

type FormValues = {
  firstName: string;
  lastName: string;
  phone: string;
};

export const ContactNurse = ({
  onNextStep,
  handleShowCalendar
}: {
  onNextStep: () => void;
  handleShowCalendar: () => void;
}) => {
  const { sendGoogleAnalyticsEvent } = useGoogleTagManager();
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: ''
    }
  });
  const immediateConsultVariant = useImmediateConsultVariant();
  const [submissionError, setSubmissionError] = useState(false);

  const onSubmit = async (data: FormValues) => {
    const userEmail =
      new URLSearchParams(window.location.search).get('email') || localStorage.getItem('prescreeningUserEmail');
    const shaEmail = userEmail ? formatStringToSha256Hash(userEmail) : '';

    try {
      setSubmissionError(false);

      const response = await fetch(`${settings.xApiUrl}/forms/v1/patients/instant`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: {
            type: 'patient',
            attributes: {
              email: userEmail,
              ...data
            }
          }
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const trackingPayload = {
        event_category: 'cal.com',
        event_label: 'Immediate Nurse Consultation',
        sha_email: shaEmail,
        sha_gads_phone_number: formatStringToSha256Hash(data.phone),
        sha_fb_phone_number: formatStringToSha256Hash(data.phone),
        sha_first_name: formatStringToSha256Hash(data.firstName),
        sha_last_name: formatStringToSha256Hash(data.lastName)
      };
      sendGoogleAnalyticsEvent(GoogleAnalyticsEventName.SUBMIT_BOOKING, trackingPayload);

      localStorage.setItem('immediateNurseConsultTrackingPayload', JSON.stringify(trackingPayload));

      if (settings.instantConnectPhoneNumber) {
        window.location.href = `tel:${settings.instantConnectPhoneNumber}`;
        onNextStep();
      }
    } catch (error) {
      console.error('Error contacting nurse:', error);
      setSubmissionError(true);
    }
  };

  return (
    <Stack minHeight="70vh" flexDirection={'column'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" padding={5} flex={1}>
          <Typography variant="h6" fontWeight="bold" marginBottom={6}>
            Provide the below details to connect to a nurse.
          </Typography>

          {submissionError && (
            <Alert severity="error" sx={{ marginBottom: 3 }}>
              Something went wrong. Please try again or schedule for later.
            </Alert>
          )}

          <Box marginBottom={5}>
            <Controller
              name="firstName"
              control={control}
              rules={{ required: 'First name is required' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="standard"
                  label="First name"
                  fullWidth
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName?.message}
                />
              )}
            />
          </Box>

          <Box marginBottom={5}>
            <Controller
              name="lastName"
              control={control}
              rules={{ required: 'Last name is required' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="standard"
                  label="Last name"
                  fullWidth
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName?.message}
                />
              )}
            />
          </Box>

          <Box marginBottom={5}>
            <PhoneInputWithCountry
              defaultCountry="AU"
              international
              value={getValues('phone')}
              inputComponent={CustomPhoneInput}
              onChange={(value: string) => setValue('phone', value)}
              error={Boolean(errors.phone)}
              helperText={errors.phone?.message}
              name="phone"
              control={control}
              rules={{
                required: 'Phone number is required',
                validate: (value: string) => (isValidPhoneNumber(value) ? undefined : 'Invalid phone number')
              }}
            />
          </Box>

          <Box marginBottom={5}>
            <Alert
              severity="info"
              icon={<AccessTimeIcon />}
              sx={{
                backgroundColor: lightBlue[50],
                color: blueGrey[900]
              }}
            >
              By clicking{' '}
              {immediateConsultVariant === ImmediateConsultExperiment.VARIANT_1
                ? 'Speak to a Nurse'
                : 'Start consultation'}
              , I understand that Alternaleaf may record and/or transcribe the consultations I may have with Alternaleaf
              health care providers for training and quality purposes. In proceeding with this consultation, I am
              consenting to my consultations being recorded. Average wait time approx. 5-15 minutes.
            </Alert>
          </Box>

          <Box>
            <Button type="submit" variant="contained" fullWidth startIcon={<PhoneIcon />}>
              <Typography fontSize={18} padding={2}>
                {immediateConsultVariant === ImmediateConsultExperiment.VARIANT_1
                  ? 'Speak to a Nurse'
                  : 'Start consultation'}
              </Typography>
            </Button>
          </Box>
        </Box>
      </form>
      {[ImmediateConsultExperiment.CONTROL, ImmediateConsultExperiment.VARIANT].includes(immediateConsultVariant) && (
        <Box padding={5} marginTop={'auto'} marginBottom={5}>
          <Button variant="outlined" startIcon={<CalendarTodayIcon />} onClick={handleShowCalendar} fullWidth>
            Not ready now? Schedule for later
          </Button>
        </Box>
      )}
    </Stack>
  );
};
