import Logo from '@/components/svg/Logo';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import Footer from '@/pcp/components/components/Footer';
import Header from '@/pcp/components/components/Header';
import Layout from '@/pcp/components/Layout';
import type { PCPErrorType } from '@/pcp/constants/types';
import Error from '@/pcp/content/Error';
import { PatientPortalPage, ConfigProvider as TelecomConfigProvider, configDefaults } from '@montugroup/clinic-telecom';
import { Box } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { FF_C4C_VIDEO_CONSULT } from '../../constants/featureFlags';

const queryClient = new QueryClient();
const LogoElement = Logo({});

export const VideoConsultation = () => {
  const { key } = useParams<{ key: string }>();
  const { flags, loading: isFeatureFlagLoading } = useFeatureFlags();
  const isFeatureEnabled = flags[FF_C4C_VIDEO_CONSULT];

  // using default config for now
  const config = {
    ...configDefaults,
    logo: LogoElement,
    header: <Header />,
    footer: <Footer />
  };

  if (!isFeatureEnabled && !isFeatureFlagLoading) {
    return (
      <Layout contentMaxWidth="md">
        <Error type="404" />
      </Layout>
    );
  }

  return (
    <TelecomConfigProvider {...config}>
      <QueryClientProvider client={queryClient}>
        <Box sx={{ height: '100dvh' }}>
          {/* key will never be undefined here because the route is protected */}
          <PatientPortalPage
            renderErrorComponent={(type: PCPErrorType) => (
              <Layout contentMaxWidth="md">
                <Error type={type} />
              </Layout>
            )}
            consultKey={key!}
          />
        </Box>
      </QueryClientProvider>
    </TelecomConfigProvider>
  );
};
