import { jsx as t } from "@emotion/react/jsx-runtime";
import { Container as a, Stack as s } from "@mui/material";
const m = ({ children: e, maxWidth: i = "lg", sx: r = [], spacing: o }) => /* @__PURE__ */ t(
  a,
  {
    maxWidth: i,
    disableGutters: !0,
    sx: [{ minHeight: "100dvh", display: "flex" }, ...Array.isArray(r) ? r : [r]],
    children: /* @__PURE__ */ t(s, { spacing: o, sx: { flexGrow: 1 }, children: e })
  }
);
export {
  m as L
};
