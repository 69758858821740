import type { LDFlagSet } from 'launchdarkly-js-sdk-common';

import {
  FF_ANONYMOUS_USERS,
  FF_C4C_VIDEO_CONSULT,
  FF_CALCOM_EMBED_PARAMS,
  FF_DISABLE_CONSULTATION_BUTTONS,
  FF_ENABLE_DISCOUNT_CODE,
  FF_ENABLE_FORM_QUICK_SUBMIT,
  FF_ENABLE_GOOGLE_PLACES,
  FF_ENABLE_PCP_QOL_FORM,
  FF_ENABLE_PROMOTION_HELPER_MESSAGE,
  FF_ENABLE_REMOVE_ATSI_AND_SMOKING,
  FF_ENABLE_SHOPIFY_DISCOUNT_VALIDATION,
  FF_ENABLE_SNOWPLOW,
  FF_IMMEDIATE_NURSE_CONSULTATION,
  FF_IMMEDIATE_NURSE_CONSULTATION_VARIANTS,
  FF_MEDICARE_CHECKSUM,
  FF_PAYMENT_SKIPPED_FLOW_EXPERIMENT,
  FF_PPP_ENABLED,
  FF_PPP_TESTING
} from '@/constants/featureFlags';

const ffDefaults: LDFlagSet = {
  [FF_DISABLE_CONSULTATION_BUTTONS]: false,
  [FF_ENABLE_DISCOUNT_CODE]: false,
  [FF_ENABLE_GOOGLE_PLACES]: false,
  [FF_ENABLE_PROMOTION_HELPER_MESSAGE]: false,
  [FF_ENABLE_SNOWPLOW]: false,
  [FF_MEDICARE_CHECKSUM]: false,
  [FF_PPP_ENABLED]: false,
  [FF_PPP_TESTING]: false,
  [FF_PAYMENT_SKIPPED_FLOW_EXPERIMENT]: 'off',
  [FF_ENABLE_FORM_QUICK_SUBMIT]: false,
  [FF_ENABLE_PCP_QOL_FORM]: true,
  [FF_ENABLE_REMOVE_ATSI_AND_SMOKING]: false,
  [FF_ENABLE_SHOPIFY_DISCOUNT_VALIDATION]: false,
  [FF_ANONYMOUS_USERS]: false,
  [FF_IMMEDIATE_NURSE_CONSULTATION]: false,
  [FF_CALCOM_EMBED_PARAMS]: false,
  [FF_IMMEDIATE_NURSE_CONSULTATION_VARIANTS]: 'control',
  [FF_C4C_VIDEO_CONSULT]: false
};

export default ffDefaults;
