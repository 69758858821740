import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, Button, Collapse, TextField, Typography, styled } from '@mui/material';
import type { KeyboardEvent } from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { FF_ENABLE_PROMOTION_HELPER_MESSAGE } from '@/constants/featureFlags';
import { useValidateDiscountCode } from '@/hooks/discount-code/useValidateDiscountCode';
import useFeatureFlags from '@/hooks/useFeatureFlags';

const Wrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '28.125rem'
  }
}));

const DiscountCode = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '1rem',
  marginBottom: '2rem',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row'
  }
}));

const ApplyButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    minWidth: 'auto'
  }
}));

const termsAndConditionsLink = 'https://www.alternaleaf.com.au/promotion-terms';

function discountCodeInputFeedback(validated?: boolean, isPromotionHelperMessageEnabled?: boolean) {
  if (typeof validated !== 'boolean') {
    return null;
  }

  if (validated) {
    return {
      InputProps: {
        endAdornment: <CheckCircleIcon fontSize="large" color="success" />
      },
      helperText: isPromotionHelperMessageEnabled ? (
        <Typography component="span">
          <strong>Your promo code has been applied, but you are eligible for a better offer. </strong> Your discounted
          consult price will be reflected on the consultation payment page.{' '}
          <a href={termsAndConditionsLink} target="_blank" rel="noopener noreferrer" style={{ color: '#1E0421' }}>
            View full offer terms and conditions.
          </a>
        </Typography>
      ) : (
        <Typography component="span">
          <strong>Congratulations!</strong> Your coupon code has been successfully applied. Your discount will be
          applied once you reach the consultation payment page.{' '}
          <a href={termsAndConditionsLink} target="_blank" rel="noopener noreferrer" style={{ color: '#1E0421' }}>
            View full offer terms and conditions.
          </a>
        </Typography>
      )
    };
  }

  // not valid
  return {
    InputProps: {
      endAdornment: <ErrorIcon fontSize="large" color="error" />
    },
    helperText: isPromotionHelperMessageEnabled ? (
      <Typography component="span">
        <strong>
          The promo code you have entered is not valid or has expired, but you are eligible for a better offer.{' '}
        </strong>
        Your discounted consult price will be reflected on the consultation payment page.{' '}
        <a href={termsAndConditionsLink} target="_blank" rel="noopener noreferrer" style={{ color: '#1E0421' }}>
          View full offer terms and conditions.
        </a>
      </Typography>
    ) : (
      <Typography component="span">
        The promo code you have entered is not valid or has expired. Please double-check your code and try again.{' '}
        <a href={termsAndConditionsLink} target="_blank" rel="noopener noreferrer" style={{ color: '#1E0421' }}>
          View full offer terms and conditions.
        </a>
      </Typography>
    )
  };
}

export function AddDiscountCode() {
  const { flags } = useFeatureFlags();
  const isPromotionHelperMessageEnabled = flags[FF_ENABLE_PROMOTION_HELPER_MESSAGE];

  const [searchParams, setSearchParams] = useSearchParams();
  const promocode = searchParams.get('promocode') || '';

  const [expanded, setExpanded] = useState(false);
  const [discountCodeInput, setDiscountCodeInput] = useState<string>(promocode || '');
  const [discountCode, setDiscountCode] = useState<string>(promocode || '');

  const { isValidated: discountValidated, isLoading: isValidating, refetch } = useValidateDiscountCode(discountCode);

  useEffect(() => {
    if (discountValidated) {
      setExpanded(true);
      searchParams.set('promocode', discountCode);
      setSearchParams(searchParams);
      return;
    }

    searchParams.delete('promocode');
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountValidated, searchParams, setSearchParams]);

  const handleExpandToggle = () => {
    setExpanded(!expanded);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountCodeInput(e.target.value.toLocaleUpperCase());
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      applyDiscountCode();
    }
  };

  const applyDiscountCode = () => {
    if (discountCodeInput) {
      if (discountCodeInput === discountCode) {
        refetch();
      } else {
        setDiscountCode(discountCodeInput);
      }
    }
  };

  return (
    <Wrapper>
      <Typography
        variant="body1"
        component="h2"
        fontWeight={600}
        marginBottom={8}
        marginTop={8}
        sx={{ cursor: 'pointer', textDecoration: 'underline' }}
        onClick={handleExpandToggle}
        id="have-promo-code" // do not change this id, it is used for tracking purposes.
      >
        I have a discount code
      </Typography>
      <Collapse in={expanded}>
        <DiscountCode>
          <TextField
            id="input-promo-code"
            type="text"
            variant="filled"
            fullWidth
            placeholder="Enter code"
            {...discountCodeInputFeedback(discountValidated, isPromotionHelperMessageEnabled)}
            disabled={isValidating}
            value={discountCodeInput}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
          />
          <Box paddingTop={2}>
            <ApplyButton disabled={isValidating} variant="contained" onClick={applyDiscountCode} id="apply-promo-code">
              Apply
            </ApplyButton>
          </Box>
        </DiscountCode>
      </Collapse>
      <Typography sx={{ color: 'grey', fontSize: '0.90rem' }}>No code? Proceed to booking</Typography>
    </Wrapper>
  );
}
