import { useEffect } from 'react';

import constants from '@/constants/constants';
import Layout from '@/pcp/components/Layout';

export default function HomeRedirect() {
  useEffect(() => {
    window.location.href = `${constants.circuitLoginUrl}`;
  }, []);

  return <Layout loading showHeader={false} showFooter={false} />;
}
