import { FF_ENABLE_SHOPIFY_DISCOUNT_VALIDATION } from '@/constants/featureFlags';
import { apiPatientPortalContract } from '@montugroup/pms-api-contracts';

import settings from '@/constants/constants';
import { QueryKey } from '@/constants/query';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { useQuery } from '@tanstack/react-query';
import { initQueryClient } from '@ts-rest/react-query';
import { useRef } from 'react';
import { useLegacyValidateDiscountCode } from './useLegacyValidateDiscountCode';

/**
 * API Fetcher hook to call and return data from the `validateConsultationDiscount` endpoint.
 *
 * @param discountCode - The discount code string to validate
 *
 * @returns Raw returned data, validation result, error state, loading state, and refetch function
 */
export function useValidateDiscountCode(discountCode: string) {
  const { flags } = useFeatureFlags();
  const ffEnableShopifyDiscountValidation = flags[FF_ENABLE_SHOPIFY_DISCOUNT_VALIDATION];
  const discountCodeProvided = typeof discountCode === 'string' && discountCode !== '';

  const queryClientRef = useRef(initQueryClient(apiPatientPortalContract, { baseUrl: settings.baseUrl }));
  const tsRestClient = queryClientRef.current;

  const queryKey = [QueryKey.ValidateConsultationDiscount, discountCode];

  // Use direct useQuery with custom queryFn
  let { data, isError, isLoading, refetch } = useQuery({
    queryKey,
    enabled: discountCodeProvided && (ffEnableShopifyDiscountValidation || false),
    refetchOnMount: 'always',
    staleTime: 1000,
    queryFn: async () => {
      const response = await tsRestClient.validateConsultationDiscount.query({
        params: { discountCode }
      });
      return response;
    }
  });

  // handle legacy api (feature flag disabled)
  const legacyValidation = useLegacyValidateDiscountCode(discountCode, !ffEnableShopifyDiscountValidation);
  if (!ffEnableShopifyDiscountValidation) {
    data = legacyValidation.data;
    isError = legacyValidation.isError;
    isLoading = legacyValidation.isLoading;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    refetch = legacyValidation.refetch as any;
  }

  // handle validation status
  let isValidated: boolean | undefined = undefined;
  if (data) {
    switch (data.status) {
      case 200:
        isValidated = data.body?.data?.validated || false;
        break;
      case 404:
        isValidated = false;
        isError = true;
        break;
      default:
        isValidated = false;
    }
  }

  return { isValidated, data, isError, isLoading, refetch };
}
