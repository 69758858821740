import { FFLoadingStateProvider } from '@/components/featureFlags/FFLoadingStateProvider';
import settings from '@/constants/constants';
import { getAppEnv, getFormConfig } from '@/constants/env';
import { FF_ENABLE_SNOWPLOW } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import ConsultEligible from '@/pages/ConsultEligible';
import HomeLinks from '@/pages/HomeLinks';
import HomeRedirect from '@/pages/HomeRedirect';
import PreConsultationForm from '@/pages/PreConsultationForm';
import PreScreeningForm from '@/pages/PreScreeningForm';
import QualityOfLifeForm from '@/pages/QualityOfLifeForm';
import Layout from '@/pcp/components/Layout';
import pcpSettings from '@/pcp/constants/constants';
import Error from '@/pcp/content/Error';
import PCPortal from '@/pcp/pages/PCPortal';
import configureGooglePlaces from '@/providers/googlePlaces';
import flattenObject from '@/utils/flattenObject';
import Logger from '@/utils/logger';
import { WithSnowplow } from '@montugroup/data-collection';
import { alternaleaf, BrandThemeProvider } from '@montugroup/themes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createContext, useMemo } from 'react';
import type { FieldValues } from 'react-hook-form';
import { Route, Routes } from 'react-router-dom';
import { snowplowSettings } from './constants/constants';
import useTrackingProviders from './hooks/useTrackingProviders';
import { VideoConsultation } from './pages/consultation/VideoConsultation';
import { generateQueryConfig } from './utils/generateQueryConfig';

const DEFAULT_QUERY_CONFIG = generateQueryConfig();
const queryClient = new QueryClient(DEFAULT_QUERY_CONFIG);

type LogEventsContextType = { logEvents: (event: string, data: FieldValues) => void };
export const LogEventsContext = createContext<LogEventsContextType>({
  logEvents: () => undefined
});

const logger = new Logger('App.tsx');

const Error404 = () => (
  <Layout contentMaxWidth="md">
    <Error type="404" />
  </Layout>
);

function App() {
  const appEnv = getAppEnv();
  const isProduction = appEnv === 'production';
  const targetForm = getFormConfig();
  const { flags } = useFeatureFlags();
  const { braze } = useTrackingProviders(settings);

  configureGooglePlaces(settings);

  function logEvents(event: string, data: FieldValues) {
    if (isProduction) {
      const flattenData = flattenObject(data);
      const isConsultationForm = event.includes('pre-consultation');
      const email = isConsultationForm ? data?.email.toLowerCase() : data?.email_and_privacy?.email.toLowerCase();
      const payload = new URLSearchParams({ eventType: 'restore', ...flattenData }).toString();
      try {
        if (!email) {
          return;
        }

        const brazeUser = braze.changeUser(email);

        if (!brazeUser) {
          return;
        }

        brazeUser.setEmail(email);

        if (isConsultationForm) {
          if (data?.consent_form?.first_name) {
            brazeUser.setFirstName(data.consent_form.first_name);
          }
        }
      } catch (e) {
        logger.error(e);
      }
      // https://montugroup.atlassian.net/browse/MM-162
      // generate a url for braze to allow patient to come back and continue filling the forms
      braze.brazeTrackEvent(event, { url: `${window.location.origin}/?${payload}` });
    }
  }

  const logEventsContextValue: LogEventsContextType = useMemo(() => ({ logEvents }), [logEvents]);

  function getRootComponent() {
    switch (targetForm) {
      case 'screening':
        return <PreScreeningForm callback={logEvents} />;
      case 'consult':
        return <PreConsultationForm callback={logEvents} />;
      case 'portal':
        return <HomeRedirect />;
      case 'eligible':
        return <ConsultEligible />;
      case 'all':
        if (!isProduction) {
          return <HomeLinks />;
        }
        return <HomeRedirect />;
    }
  }

  const enableSnowplow = flags[FF_ENABLE_SNOWPLOW];
  return WithSnowplow(
    <QueryClientProvider client={queryClient}>
      <BrandThemeProvider theme={alternaleaf}>
        <FFLoadingStateProvider>
          <LogEventsContext.Provider value={logEventsContextValue}>
            <Routes>
              {/* Root (homepage) - dependent on `VITE_FORM_CONFIG` */}
              <Route path="/" element={getRootComponent()} />
              {/* Pre-Consultation Portal path */}
              {(targetForm === 'portal' || targetForm === 'all') && (
                <>
                  <Route path={`${pcpSettings.frontendPath}/:token?`} element={<PCPortal />} />
                  <Route
                    path={settings.qualityOfLifeForm.frontendPath}
                    element={<QualityOfLifeForm formType="standalone" />}
                  />
                </>
              )}

              {/* Form paths */}
              {targetForm === 'all' && (
                <>
                  <Route path="pre-screening" element={<PreScreeningForm callback={logEvents} />} />
                  <Route path="pre-consultation" element={<PreConsultationForm callback={logEvents} />} />
                </>
              )}

              {/*  Pre-Screening path */}
              {(targetForm === 'screening' || !isProduction) && (
                <Route path="consult-eligible" element={<ConsultEligible />} />
              )}

              {/* Add video consultation route */}
              <Route path="video-consultation/:key" element={<VideoConsultation />} />

              {/* All other unmatched paths */}
              <Route path="*" element={<Error404 />} />
            </Routes>
          </LogEventsContext.Provider>
        </FFLoadingStateProvider>
      </BrandThemeProvider>
    </QueryClientProvider>,
    {
      appId: snowplowSettings.appId,
      trackerName: snowplowSettings.trackerName
    },
    enableSnowplow
  );
}

export default App;
