import {
  ImmediateConsultExperiment,
  useImmediateConsultVariant
} from '@/components/experiments/EligibilityVariantImmediateConsult';
import { ButtonWrapper } from '@/components/form-builder/sections/section/styled';
import settings from '@/constants/constants';
import { GoogleAnalyticsEventName } from '@/constants/types';
import useGoogleTagManager from '@/hooks/useGoogleTagManager';
import { Button } from '@montugroup/design-system';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import { Alert, Box, Link, Typography } from '@mui/material';
import { blueGrey, lightBlue } from '@mui/material/colors';

export const NurseConsultation = ({ handleShowCalendar }: { handleShowCalendar: () => void }) => {
  const { sendGoogleAnalyticsEvent } = useGoogleTagManager();
  const immediateConsultVariant = useImmediateConsultVariant();

  const handleAnalyticsOnClick = () => {
    const storedTrackingPayload = localStorage.getItem('immediateNurseConsultTrackingPayload');
    if (storedTrackingPayload) {
      sendGoogleAnalyticsEvent(GoogleAnalyticsEventName.START_BOOKING, JSON.parse(storedTrackingPayload));
    }
  };

  return (
    <>
      <Box minHeight="70vh">
        <PhoneEnabledOutlinedIcon color="secondary" sx={{ fontSize: '2rem', marginBottom: 3 }} />
        <Typography variant="h6" component="h6" fontWeight={'bold'} marginBottom={5}>
          Your initial assessment
        </Typography>
        <Typography marginBottom={5}>
          During this consultation you and a nurse will discuss your medical history and our services.
        </Typography>
        <Typography marginBottom={8}>Feel free to ask any questions you have.</Typography>
        <Typography marginBottom={4} fontWeight={'bold'}>
          What&apos;s next?
        </Typography>
        <Typography marginBottom={5}>
          If a nurse deems you eligible for the service, they&apos;ll organise your booking with a doctor, and
          you&apos;ll receive an email with next steps.
        </Typography>
      </Box>

      <ButtonWrapper style={{ backgroundColor: 'white' }}>
        <Button
          variant="outlined"
          startIcon={<PhoneIphoneOutlinedIcon />}
          onClick={() => {
            handleAnalyticsOnClick();
            window.location.href = `tel:${settings.instantConnectPhoneNumber}`;
          }}
        >
          Disconnected? Call nurse again
        </Button>
        {immediateConsultVariant !== ImmediateConsultExperiment.VARIANT_1 && (
          <>
            <Typography textAlign={'center'}>or</Typography>
            <Button
              variant="contained"
              startIcon={<CalendarTodayIcon />}
              color={'secondary'}
              fullWidth
              onClick={handleShowCalendar}
            >
              Schedule for later
            </Button>
          </>
        )}
        <Alert
          style={{ marginTop: 10 }}
          severity="info"
          icon={<QuizOutlinedIcon />}
          sx={{
            backgroundColor: lightBlue[50],
            color: blueGrey[900]
          }}
        >
          Need assistance? Visit our{' '}
          <Link
            href="https://support.alternaleaf.com.au/hc/en-au"
            rel="noopener noreferrer"
            target="_blank"
            sx={{
              fontWeight: 'bold',
              color: blueGrey[900]
            }}
          >
            help centre
          </Link>{' '}
          for answers to frequently asked questions or to find{' '}
          <Link
            href="https://support.alternaleaf.com.au/hc/en-au/articles/34017993658905-Contact-Alternaleaf"
            rel="noopener noreferrer"
            target="_blank"
            sx={{
              fontWeight: 'bold',
              color: blueGrey[900]
            }}
          >
            our contact details
          </Link>{' '}
          for further support.
        </Alert>
      </ButtonWrapper>
    </>
  );
};
