import settings from '@/constants/constants';
import axios from 'axios';
import { useEffect, useState } from 'react';

type LegacyDiscountStatus = 'valid' | 'expired' | 'invalid';

type LegacyDiscountTransformedData =
  | {
      status: 200;
      body: {
        data: {
          message: string;
          validated: boolean;
        };
      };
      headers: Headers;
    }
  | undefined;

/**
 * Legacy API Fetcher hook to call and transform returned data from the old API endpoint.
 *
 * @param discountCode - The discount code string to validate
 * @param enabled - Whether to enable data fetching
 *
 * @returns Transformed returned data, error state, loading state, and refetch function
 */
export function useLegacyValidateDiscountCode(discountCode: string, enabled = true) {
  const [data, setData] = useState<LegacyDiscountTransformedData>(undefined);
  const [isError, setIsError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const refetch = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${settings.url}/discount/validate`, { params: { discountCode } });
      const data = response?.data;
      const discountStatus: LegacyDiscountStatus = data?.status;

      const transformedData =
        response?.status === 200
          ? {
              status: 200 as const,
              body: {
                data: {
                  message: data?.message || data?.errorMessage,
                  validated: discountStatus === 'valid'
                }
              },
              headers: response?.headers as unknown as Headers
            }
          : undefined;
      setData(transformedData);
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch {
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (enabled && discountCode) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, discountCode]);

  return { data, isError, isLoading, refetch };
}
