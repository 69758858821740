import type { AppEnv, FormConfig } from '@/pcp/constants/env';
import { getAppEnv, getFormConfig } from '@/pcp/constants/env';
import type { PCPDiscreteStage } from '@/pcp/constants/types';

// TODO: refactor/refine this file during PCP migration
// currently just a clone of the forms constants file

type CommonSettings = Record<string, unknown>;

type EnvSpecificSettings = {
  frontendPath: string;
  backendOrigin: string;
  backendApiPath: string;
  faqUrl: string;
  alternaleafUrl: string;
  circuitLoginUrl: string;
  completedApiPaths: Record<PCPDiscreteStage, string>;
  calendarEmbedJsUrl: string;
};

type GoogleTagManagerSettings = {
  googleTagManagerId: string;
  googleTagManagerAuth: string;
};

type GoogleTagManagerEnvironments = 'staging' | 'prod';

const common: CommonSettings = {};

export type EnvSettings = CommonSettings & EnvSpecificSettings;

const development: EnvSettings = {
  ...common,
  frontendPath: 'pre-consult',
  backendOrigin: 'https://pms.staging.alternaleaf.com.au',
  backendApiPath: 'api/v1/pre-prescription',
  faqUrl: 'https://www.alternaleaf.com.au/faq',
  alternaleafUrl: 'https://www.alternaleaf.com.au',
  circuitLoginUrl: 'https://patient.staging.alternaleaf.com.au/login',
  completedApiPaths: {
    pre_nurse: 'preNurseConsultation.completed',
    pre_doctor: 'preDoctorConsultation.completed',
    journey_complete: 'journeyCompleted'
  },
  calendarEmbedJsUrl: 'https://bookings.staging.alternaleaf.com.au/embed/embed.js'
};

const developmentFullStack: EnvSettings = {
  ...development,
  backendOrigin: 'http://localhost:9000'
};

const staging: EnvSettings = {
  frontendPath: 'pre-consult',
  backendOrigin: 'https://pms.staging.alternaleaf.com.au',
  backendApiPath: 'api/v1/pre-prescription',
  faqUrl: 'https://www.alternaleaf.com.au/faq',
  alternaleafUrl: 'https://www.alternaleaf.com.au',
  circuitLoginUrl: 'https://patient.staging.alternaleaf.com.au/login',
  completedApiPaths: {
    pre_nurse: 'preNurseConsultation.completed',
    pre_doctor: 'preDoctorConsultation.completed',
    journey_complete: 'journeyCompleted'
  },
  calendarEmbedJsUrl: 'https://bookings.staging.alternaleaf.com.au/embed/embed.js'
};

const production: EnvSettings = {
  frontendPath: 'pre-consult',
  backendOrigin: 'https://pms.alternaleaf.com.au',
  backendApiPath: 'api/v1/pre-prescription',
  faqUrl: 'https://www.alternaleaf.com.au/faq',
  alternaleafUrl: 'https://www.alternaleaf.com.au',
  circuitLoginUrl: 'https://circuit.montu.com.au/login/patient',
  completedApiPaths: {
    pre_nurse: 'preNurseConsultation.completed',
    pre_doctor: 'preDoctorConsultation.completed',
    journey_complete: 'journeyCompleted'
  },
  calendarEmbedJsUrl: 'https://bookings.alternaleaf.com.au/embed/embed.js'
};

const screeningGoogleTagManager: GoogleTagManagerSettings = {
  googleTagManagerId: 'GTM-WBQ3Q27',
  googleTagManagerAuth: ''
};

const consultGoogleTagManager: GoogleTagManagerSettings = {
  googleTagManagerId: 'GTM-MWGJK4N',
  googleTagManagerAuth: ''
};

const portalGoogleTagManager: GoogleTagManagerSettings = {
  googleTagManagerId: 'GTM-WZ6PK5RC',
  googleTagManagerAuth: ''
};

const googleTagManager: Record<GoogleTagManagerEnvironments, Record<FormConfig, GoogleTagManagerSettings>> = {
  staging: {
    screening: { ...screeningGoogleTagManager, googleTagManagerAuth: '1otSZZSy8hbJNJ8DPdhT3Q' },
    consult: { ...consultGoogleTagManager, googleTagManagerAuth: 'v9pl99wz7Li-6GECYTVmJg' },
    portal: { ...portalGoogleTagManager, googleTagManagerAuth: 'b483Q-QluKpvgR3KboIkoA' },
    all: { ...portalGoogleTagManager, googleTagManagerAuth: 'b483Q-QluKpvgR3KboIkoA' }
  },
  prod: {
    screening: { ...screeningGoogleTagManager, googleTagManagerAuth: '' },
    consult: { ...consultGoogleTagManager, googleTagManagerAuth: '' },
    portal: { ...portalGoogleTagManager, googleTagManagerAuth: '' },
    all: { ...portalGoogleTagManager, googleTagManagerAuth: '' }
  }
};

function getGoogleTagManagerEnvironment(env: AppEnv): GoogleTagManagerEnvironments {
  return env === 'production' ? 'prod' : 'staging';
}

function mergeGoogleTagManagerSettings(settings: EnvSettings, env: AppEnv): EnvSettings {
  const gtmEnv = getGoogleTagManagerEnvironment(env);
  const formConfig = getFormConfig();

  if (formConfig && googleTagManager[gtmEnv][formConfig]) {
    return { ...settings, ...googleTagManager[gtmEnv][formConfig] };
  }

  return settings;
}

function getEnvironmentSettings(): EnvSettings {
  const env: AppEnv = getAppEnv();
  const baseSettings: Record<AppEnv, EnvSettings> = {
    development,
    developmentFullStack,
    staging,
    production
  };

  return mergeGoogleTagManagerSettings(baseSettings[env] || development, env);
}

const settings: EnvSettings = getEnvironmentSettings();
export default settings;
