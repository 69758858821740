import { PhoneNumberTextField } from '@montugroup/design-system';
import { FormControl } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import type { PhoneNumberType } from '@/components/form-builder/FormType';
import { isValidPhoneNumber } from 'react-phone-number-input';

const PhoneNumber = ({ name, autofill, inputProps, requiredText, invalidNumberText, ...rest }: PhoneNumberType) => {
  const { control, setValue, trigger } = useFormContext();

  return (
    <FormControl sx={{ my: 2 }} fullWidth>
      <Controller
        rules={{
          required: requiredText,
          validate: (value: string | undefined) => (value && isValidPhoneNumber(value)) || invalidNumberText
        }}
        control={control}
        defaultValue={null}
        name={name}
        render={({ field: { onChange, value }, fieldState: { error, invalid, isDirty } }) => {
          return (
            <PhoneNumberTextField
              {...rest}
              control={control}
              error={!!error}
              helperText={error?.message}
              name={name}
              onChange={(val: string | undefined) => {
                onChange(val);
                trigger(name);
              }}
              value={value}
              color={isDirty && !invalid ? 'success' : 'primary'}
              inputProps={{
                ...inputProps,
                'data-autofill': autofill
              }}
              onMouseDown={(e: React.MouseEvent<Element, MouseEvent>) => {
                const target = e.target as HTMLInputElement;
                if (e.altKey && target?.dataset?.autofill) {
                  setValue(name, target?.dataset?.autofill);
                  trigger(name);
                }
              }}
            />
          );
        }}
      />
    </FormControl>
  );
};

export default PhoneNumber;
