import type { LDFlagSet } from 'launchdarkly-react-client-sdk';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { getAppEnv, viteFFOverrides } from '@/constants/env';
import * as FeatureFlagNames from '@/constants/featureFlags';
import Logger from '@/utils/logger';

import { FFLoadingStateContext } from '@/components/featureFlags/FFLoadingStateProvider';
import { useContext } from 'react';

type UseLDFeatureFlagsType = {
  flags: LDFlagSet;
  loading: boolean;
};

const logger = new Logger('useFeatureFlags');
const FF_OVERRIDE_LOCAL_STORAGE_KEY = 'FEATURE_FLAG_OVERRIDES';

const ENV_WITH_FF_OVERRIDES = ['test', 'development', 'qa', 'staging'];
const shouldApplyFFOverrides = ENV_WITH_FF_OVERRIDES.includes(getAppEnv());

const getFFOverrides = (): { [key: string]: boolean } => {
  let overrides = {};
  const localStorageFFOverrides = localStorage.getItem(FF_OVERRIDE_LOCAL_STORAGE_KEY);
  const envFFOverrides = viteFFOverrides();

  if (localStorageFFOverrides || envFFOverrides) {
    try {
      const parsedLocalStorageFFOverrides = localStorageFFOverrides ? JSON.parse(localStorageFFOverrides) : {};

      overrides = {
        ...(envFFOverrides ?? {}),
        ...parsedLocalStorageFFOverrides
      };

      const overriddenKeys = Object.entries(overrides).join(' | ');
      logger.info(`Overriding Feature Flags: ${overriddenKeys}`);
    } catch {
      overrides = {};
    }
  }

  return overrides;
};

sessionStorage.setItem('FEATURE_FLAG_KEYS', JSON.stringify(Object.values(FeatureFlagNames), null, 2));
const featureFlagOverrides = getFFOverrides();

export const useFeatureFlags = (): UseLDFeatureFlagsType => {
  const LDflags = useFlags();
  const { loading } = useContext(FFLoadingStateContext);

  const updatedFlags = {
    ...LDflags,
    ...(shouldApplyFFOverrides ? featureFlagOverrides : {})
  };

  return { flags: updatedFlags, loading };
};

export default useFeatureFlags;
