import { Button } from '@montugroup/design-system';
import { ChevronLeft } from '@mui/icons-material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import useGoogleTagManager from '@/hooks/useGoogleTagManager';
import type { GTMType, TrackingConfigType } from '@/providers/TrackigTypes';

import ReactMarkdown from '../../components/markdown/ReactMarkdown';
import type { QuestionStep } from '../../FormBuilder';
import FormElement from '../../FormElement';
import { VisuallyHiddenText } from '../../styled';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { AnswersWrapper, ButtonWrapper, QuestionWrapper } from './styled';

type QuestionProps = {
  currentStepIndex: number;
  setActiveStep: (stepId: number) => void;
  required?: boolean;
  prevStepIndexId: string;
  trackingConfig?: TrackingConfigType;
  trackingData: GTMType;
} & QuestionStep;

const Question = ({
  id,
  qid,
  pageTitle,
  title,
  legend,
  subtitle,
  content,
  prevStepIndexId,
  currentStepIndex,
  setActiveStep,
  conditions,
  fieldArray = false,
  trackingConfig,
  trackingData
}: QuestionProps) => {
  const { trigger, getValues, getFieldState, formState } = useFormContext();

  const handleButtonNavigation = async (stepIndex = 0) => {
    const [answer] = getValues([qid]);
    const prevStepArray: number[] = JSON.parse(sessionStorage.getItem(prevStepIndexId) || `[]`);

    // previous step
    if (stepIndex < currentStepIndex) {
      const prevStep = prevStepArray.pop();
      sessionStorage.setItem(prevStepIndexId, JSON.stringify(prevStepArray));
      setActiveStep(prevStep ?? --currentStepIndex);
      // conditional flow based on answer
    } else if (conditions && conditions[answer]) {
      if (typeof conditions[answer] === 'number') {
        setActiveStep(conditions[answer] - 1);
      }
    } else {
      // next step
      const isValid = await trigger([qid]);
      if (isValid) {
        setActiveStep(stepIndex);
      }
    }
  };

  const { isDirty, isTouched, invalid } = getFieldState(qid, formState);

  const [answer] = getValues([qid]);
  const showButton = (isTouched || isDirty || answer) && !invalid;
  const { sendGoogleAnalyticsEvent } = useGoogleTagManager();
  const buttonText = qid === 'email_and_privacy' ? 'Show my results' : 'Next';
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (invalid) {
      sendGoogleAnalyticsEvent(trackingData.event, {
        step_number: id.toString(),
        step_name: trackingData.stepName,
        clickId: trackingData.clickId,
        error_message: trackingConfig?.errorMessage || ''
      });
    }
  }, [invalid]);

  return (
    <QuestionWrapper>
      <VisuallyHiddenText component="h1">{pageTitle}</VisuallyHiddenText>
      <Typography variant="h6" component="h2" sx={{ mb: 4 }}>
        {title}
      </Typography>
      {subtitle && <ReactMarkdown>{subtitle}</ReactMarkdown>}
      <fieldset style={{ border: 'none', margin: '0', padding: '0' }}>
        {legend && <VisuallyHiddenText as="legend">{legend}</VisuallyHiddenText>}
        <AnswersWrapper>
          {content.map((item, index) => {
            const elementName = fieldArray && item.type !== 'text' ? `${qid}.${item.name}` : qid;
            return <FormElement key={`${qid}.${index}`} {...item} name={elementName} />;
          })}
        </AnswersWrapper>
      </fieldset>
      <ButtonWrapper
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: '0.5rem'
        }}
      >
        {currentStepIndex > 0 && (
          <Button
            sx={{
              width: isDesktop ? 'auto' : '100%',
              order: isDesktop ? 1 : 2
            }}
            size="large"
            type="button"
            variant="outlined"
            onClick={() => handleButtonNavigation()}
          >
            {isDesktop && <ChevronLeft style={{ marginLeft: '-0.4rem' }} />}
            <Box ml={1}>Back</Box>
          </Button>
        )}
        {showButton && (
          <Button
            type="button"
            sx={{
              width: isDesktop ? 'auto' : '100%',
              order: isDesktop ? 2 : 1
            }}
            size="large"
            variant="contained"
            onClick={() => handleButtonNavigation(Number(currentStepIndex) + 1)}
            id={trackingData.clickId}
            data-tracking-id={trackingData.event}
            disabled={!isDirty && !answer}
          >
            {buttonText}
          </Button>
        )}
      </ButtonWrapper>
    </QuestionWrapper>
  );
};

export default Question;
